var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "ms-1 pt-3 mt-2 mb-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _vm.step !== 5
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left",
                          attrs: { cols: "2", md: "1", sm: "1" },
                        },
                        [
                          _c(
                            "v-progress-circular",
                            {
                              attrs: {
                                rotate: -90,
                                size: 50,
                                width: 5,
                                value: 20 * _vm.step,
                                color: "teal",
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.step + 1) + "/6 ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "ml-1 mt-1 grey--text text--darken-4",
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t(_vm.stepLabel[_vm.step + 1]))
                                ),
                              ]),
                            ]
                          ),
                          _c("v-row", { staticClass: "ml-1" }, [
                            _vm.stepLabel[_vm.step + 2] != undefined
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "grey--text text--darken-1 caption",
                                  },
                                  [
                                    _vm._v(
                                      " Next: " +
                                        _vm._s(
                                          _vm.$t(_vm.stepLabel[_vm.step + 2])
                                        )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-left",
                          attrs: { cols: "2", md: "1", sm: "1" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none rounded-lg",
                              attrs: { color: "primary", outlined: "" },
                              on: { click: _vm.onSignIn },
                            },
                            [_vm._v(_vm._s(_vm.$t("SignIn")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }