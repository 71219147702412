<template>
  <div>
    <v-row class="ms-1 pt-3 mt-2 mb-2">
      <v-col cols="12" lg="4">
        <v-row v-if="step !== 5">
          <v-col cols="2" md="1" sm="1" class="text-left">
            <v-progress-circular
              :rotate="-90"
              :size="50"
              :width="5"
              :value="20 * step"
              color="teal"
            >
              {{ step + 1 }}/6
            </v-progress-circular>
          </v-col>

          <v-col cols="6">
            <v-row class="ml-1 mt-1 grey--text text--darken-4">
              <span>{{ $t(stepLabel[step + 1]) }}</span>
            </v-row>
            <v-row class="ml-1">
              <span
                v-if="stepLabel[step + 2] != undefined"
                class="grey--text text--darken-1 caption"
              >
                Next: {{ $t(stepLabel[step + 2]) }}</span
              >
            </v-row>
          </v-col>
          <v-col cols="2" md="1" sm="1" class="text-left">
            <v-btn
              color="primary"
              outlined
              class="text-none rounded-lg"
              @click="onSignIn"
              >{{ $t("SignIn") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    step: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      stepLabel: {
        1: "choosePlan",
        2: "companyAndBilling",
        3: "Plan Summary",
        4: "personalDetails",
        5: "emailVerification",
        6: "setPassword",
        7: "Welcome",
      },
    };
  },
  methods: {
    onSignIn() {
      this.$router.push("/signin").catch();
    },
  },
};
</script>
